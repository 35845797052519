/* @media only screen and (max-width: 480px) and (min-width: 321px) {
    .Text1 {
        font-size: 1.5rem !important;
        font-weight: 600;
        margin-bottom: 12px;
        
    }

    .Grid {
        margin: 1% !important
    }

    .box{
        width: auto !important;
    }
}

@media (min-width: 768px) and (max-width: 991.95px) {
    .Grid {
        margin: 1% !important
    }

    .About {
        margin-top: 0px !important;
    }



    .vertical {
        display: none;
    }

    .block{
        display: block;
    }


    
} */

@media screen and (max-width: 400px) {
    .pdng{
        padding: 4% !important;
    }
  }

  /* @media screen and (max-width: 400px) {
    .typo{
        display: block !important;
        justify-content: center !important;
        align-items: center !important;
        margin: auto 0 !important;
    }
  } */