/* @media screen and (max-width: 400px) {
    .imgg{
        background-image: `url(${./Im})`;
        height: 650px;
        background-size: contain;
        /* background-repeat: */
         /* background-attachment: scroll: 
    }
  } */

  /* @media screen and (max-width : 1100px) and (min-width: 768px){
    .fir{
        font-size: 34px !important;
    }
  } */
  @media screen and (max-width : 1100px) and (min-width: 768px){
    .firr{
        font-size: 14px !important;
        text-align: justify;
        padding-left: 8%;
    }
  }
  @media screen and (max-width : 1100px) and (min-width: 768px){
    .imag{
      width:90% !important;
      height: 80% !important;
      padding-top: 10%;
      padding-left: 8%;
    }
  }

  @media screen and (max-width: 1024px) {
    .fir{
        font-size: 34px !important;
        padding-left: 0% !important;
        text-align: center !important;
    }
  }
  @media screen and (max-width: 400px) {
    .firr{
        font-size: 24px !important;
        text-align: justify;
        padding-left: 2%;
        padding-right: 2%;
    }
  }