@media screen and (max-width: 480px) {
    .fom {
     padding: 0% !important;
    }
  }

  @media screen and (max-width: 480px) {
    .bo {
     padding: 45px 50px 45px 22px !important
    }
  }

  @media screen and (max-width: 480px) {
    .title {
     font-size: 40px !important;
     line-height: 1.2 !important;
    }
  }