@media screen and (max-width: 480px) {
    .but {
      margin-left: 0% !important;
      width: 250px !important
    }
  }

  @media screen and (max-width: 480px) {
    .im {
      width: 50% !important
    }
  }
  /* @media screen and (max-width: 1400px) {
    .im {
      margin-left: 45% !important;
    }
  } */

  .navigation {
    position: relative;
    display: inline-block;
  }
  .navigation-content {
    display: none;
    position: absolute;
    background-color: #273773;
    min-width: 187px;
    border-radius: 4px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  .navigation a {
    color: white;
    padding: 12px;
    margin: 0;
    text-decoration: none;
    display: block;
  }
  .navigation a:hover {
    /* background-color: #ddd; */
    border-radius: 4px;
    
  }
  .navigation:hover .navigation-content {
    display: block;
  }

 