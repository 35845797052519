@media screen and (max-width: 1024px) and (min-width: 768px){
    .contact {
      
      padding-top: 15% !important;
    }
  } 

  @media screen and (max-width: 400px) {
    .ak {
    display: block !important;  
    }
  }

  @media screen and (max-width: 400px) {
    .fld {
    width: 91% !important
    }
  }
  @media screen and (max-width: 1024px) and (min-width: 768px){
    .fld {
      
      width: 91% !important
    }
  }