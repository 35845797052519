@media screen and (max-width: 480px) {
    .lo {
        display: block;
      margin:auto;
      margin-top: 2% !important;
    }
  }

  @media screen and (max-width: 480px) {
    .title {
     font-size: 22px !important;
    }
  }
