.hoverimg0:hover {
    background-image:url("contactus.png");
    background-size: cover;
    background-color: gray;
    color: white;
}

.hoverimg1:hover {
    background-image:url("./Images/partner.png");
    background-size: cover;
    background-color: gray;
    color: white;
}

@media screen and (max-width: 400px){
    .logo {
      display: flex;
      justify-content: center;
      margin-top: 16% !important;
    }
  } 

  @media screen and (max-width: 400px){
    .text1 {
      text-align: center !important;
     font-size:  28px !important;
   margin-top: 4% !important;
   margin-bottom: 4% !important;
      
    }
  } 
  @media screen and (max-width: 400px){
    .text2 {
      text-align: center !important;
     font-size:  23px !important;
   
      
    }
  } 
  @media screen and (max-width: 400px){
    .appl {
      text-align: center !important;
     margin-top: 10% !important;
   
      
    }
  } 

  @media screen and (max-width: 1024px) and (min-width: 768px){
    .name {
      padding-left: 10% !important;
      padding-right: 10% !important;
      padding-top: 5% !important;
    }
  }

  @media screen and (max-width: 400px) {
    .name {
      padding-left: 10% !important;
      padding-right: 10% !important;
      padding-top: 5% !important;
    }
  }
