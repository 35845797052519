@media screen and (max-width: 400px) {
    .imaage {
      width:380px !important;
    }
  }

  @media screen and (max-width: 1024px) and (min-width: 768px){
    .imaage {
      width:380px !important;
      padding-top: 40% !important;
    }
  }

  @media screen and (max-width: 1024px) and (min-width: 768px){
    .asdf {
      /* width:380px !important; */
      padding-left: 4% !important;
    }
  }

/* @media screen and (max-width: 1024px) and (min-width: 768px) {
  .typ{
    font-size: small;
  }
} */

@media screen and (max-width: 1024px) and (min-width: 768px){
  .firstcompo {
    
    left: 45% !important;
  }
}

@media screen and (max-width: 400px) {
  .firstcompo {
    
    left: 40% !important;
  }
}

@media screen and (max-width: 400px) {
  .course {
    
    text-align: center;
  }
}

