@media screen and (max-width: 480px){
    .mod {
     width: 400px !important;
     
    }
  }
  @media screen and (max-width: 480px){
    .mod1 {
     margin-top: 14% !important;
     
    }
  }
  @media screen and (max-width: 480px){
    .cad {
     margin-top: 130% !important;
     
    }
  }